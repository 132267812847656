import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import theme from "../theme"

const Content = styled(ContentWrapper)`
  color: ${theme.color.white}; 
`

const Contact = () => (
  <Layout>
    <SEO 
      title="Thanks for buying our beans"
      description="" />

    <Header />

    <BackgroundWrapper className="backgroundBlue">

      <Content>
 
        <h1>Thanks for buying our beans</h1>
        <p>Please allow up to 7 working days for delivery. If you have any questions get in touch with our customer service team at <a href="mailto:hola@chivacoffee.com">hola@chivacoffee.com</a></p>
     
      </Content>
      
    </BackgroundWrapper>

  </Layout>
)

export default Contact
